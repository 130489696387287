html,
body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  height: 100%;
  background-color: #f2f4f8;
}

html {
  margin: 0 auto;
}

body {
  width: 100% !important; // 解决antd抽屉组件导致留白问题
  font-size: 14px;
  min-width: 1280px;
}

* {
  box-sizing: border-box;
}

:focus-visible {
  outline: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

ul,
ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

button {
  -webkit-tap-highlight-color: rgb(0 0 0 / 0%);
}

button:active,
button:focus,
button:hover {
  outline: none;
}

a {
  color: inherit;
  text-decoration: none;
}

// input::input-placeholder {
//   letter-spacing: 0;
//   color: rgb(255 255 255 / 50%);
// }

input::placeholder {
  /* Mozilla Firefox 19+ */
  letter-spacing: 0;
  color: rgb(255 255 255 / 50%);
}

input:placeholder {
  /* Mozilla Firefox 4 to 18 */
  letter-spacing: 0;
  color: rgb(255 255 255 / 50%);
}

// textarea::input-placeholder {
//   letter-spacing: 0;
//   color: rgb(255 255 255 / 50%);
// }

textarea::placeholder {
  /* Mozilla Firefox 19+ */
  letter-spacing: 0;
  color: rgb(255 255 255 / 50%);
}

textarea:placeholder {
  /* Mozilla Firefox 4 to 18 */
  letter-spacing: 0;
  color: rgb(255 255 255 / 50%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  appearance: none !important;
  margin: 0;
}

input[type="number"] {
  appearance: textfield;
}

.ellipsis-1 {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
  -webkit-line-clamp: 2;

  /*! autoprefixer: off */
  -webkit-box-orient: vertical;
}

.form-item-required-before::before {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.form-item-required-after::after {
  display: inline-block;
  margin-inline-end: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: "*";
}

.hover-bg-primary:hover {
  background: #009daa;
}

.hover-bg-gray:hover {
  background: #f7f8fa;
}

.hover-bg-sub-primary:hover {
  background: #f0fcfc !important;
}

.hover-color-primary:hover {
  color: #009daa;
}

.hover-underline:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hover-color-vip:hover {
  color: #ff7d00;
}
