.input-color-primary {
  .ant-input-number-input {
    color: #37aeb9 !important;
  }
}

.input-color-gray {
  .ant-input-number-input {
    color: #999 !important;
  }
}

.tabs-schedule-sheet {
  .ant-tabs-nav::before {
    display: none;
  }

  .ant-tabs-tab {
    background: #fff !important;
  }

  .ant-tabs-tab-active {
    background: #37aeb9 !important;

    .ant-tabs-tab-btn {
      color: #fff !important;
    }
  }
}

.tabs-full-screen {
  height: 100%;

  .ant-tabs-content {
    height: 100%;

    .ant-tabs-tabpane {
      height: 100%;
    }
  }
}

.select-login-style {
  .ant-select-selector {
    border: 0 !important;
    background: #f4f4f4 !important;
  }
}

.select-dropdown-wrap {
  .ant-select-item-option-content {
    white-space: pre-wrap !important;
  }
}

.modal-content-no-padding {
  &.ant-modal-wrap .ant-modal-content {
    padding: 0;
  }
}

.modal-content-radius-16 {
  &.ant-modal-wrap .ant-modal-content {
    border-radius: 16px !important;
  }
}

.modal-header-no-margin {
  .ant-modal-header {
    margin: 0 !important;
  }
}

.modal-transparent {
  &.ant-modal-wrap .ant-modal-content {
    background: transparent;
    box-shadow: none;
  }
}

.modal-bg-gradient {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(180deg, #97eaf1 0%, #fff 31.16%);
    border-radius: 16px;
  }
}

.modal-bg-gradient-2 {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(180deg, rgb(0 229 238 / 30%) 0%, #fff 40%), #fff;
    border-radius: 16px;
  }
}

.modal-bg-gradient-light {
  &.ant-modal-wrap .ant-modal-content {
    background: linear-gradient(0deg, rgb(255 255 255 / 50%), rgb(255 255 255 / 50%)),
    linear-gradient(180deg, #d9fcff 0%, #fff 31.16%);
    border-radius: 16px;
  }
}

.table-header-background-white {
  .ant-table-thead {
    .ant-table-cell {
      background-color: #fff !important;
      color: #86909c !important;
      font-weight: 400 !important;
    }
  }
}

.table-header-background-blue {
  .ant-table-thead {
    .ant-table-cell {
      background-color: #f7f8fa !important;
      color: #1d2129 !important;
      font-weight: 400 !important;
    }
  }
}

.table-cell-color-gray {
  .ant-table-tbody {
    .ant-table-cell {
      color: #4e5969 !important;
    }
  }
}

.table-input-ghost {
  input {
    box-shadow: none !important;
    outline: 0 !important;
    background-color: transparent !important;
  }
}

.table-cell-middle-padding {
  .ant-table-tbody {
    .ant-table-cell {
      padding: 9px 16px !important;
      line-height: 1 !important;
    }
  }
}

.table-input-text-align-center {
  .ant-input {
    text-align: center !important;
  }
}

.table-row-no-hover {
  td.ant-table-cell.ant-table-cell-row-hover {
    background: initial !important;
  }

  .ant-table-cell-fix-left {
    background: #fff !important;
  }

  .ant-table-cell-fix-right {
    background: #fff !important;
  }

  .table-background-color-gray {
    background: #f7f8fa !important;
  }
}

.table-cell-hover-outline {
  padding: 2px 8px;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    outline: 1px solid #ddd;
  }
}

.form-item-label-no-padding {
  .ant-form-item-label {
    padding: 0 !important;
  }
}

.input-align-center {
  input {
    text-align: center !important;
  }
}

.carousel-controller-dot-bottom {
  .slick-dots {
    li {
      width: 6px !important;
      height: 6px !important;
      border-radius: 50% !important;

      button {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        background-color: #1d212999 !important;
      }
    }

    .slick-active {
      button {
        background-color: #37aeb9 !important;
      }
    }
  }
}

.carousel-controller-dot-right-bottom {
  .slick-dots {
    li {
      width: 6px !important;
      height: 6px !important;
      border-radius: 50% !important;

      button {
        width: 6px !important;
        height: 6px !important;
        border-radius: 50% !important;
        background-color: #1d212999 !important;
      }
    }

    .slick-active {
      button {
        background-color: #37aeb9 !important;
      }
    }
  }
}

.tour-hide-close {
  .ant-tour-close {
    display: none !important;
  }
}

.hover-border-primary:hover {
  border-color: #37aeb9 !important;
}

.hover-color-primary:hover {
  cursor: pointer;
  color: #37aeb9 !important;
}

.hover-scale {
  transition: all 0.2s ease-in-out;

  &:hover {
    transform: scale(1.04);
  }
}

.tabs-tag-style {
  .ant-tabs-nav {
    &::before {
      display: none;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: #f2f3f5;
        padding: 6px 18px;
        border-radius: 999px;
        color: #4e5969;

        &:hover {
          color: #37aeb9;
        }
      }

      .ant-tabs-tab-active {
        background: #f0fcfc;
        color: #37aeb9;
      }

      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
}

.tabs-tag-style-primary {
  .ant-tabs-nav {
    &::before {
      display: none;
    }

    .ant-tabs-nav-operations {
      padding: 0 !important;
    }

    .ant-tabs-nav-list {
      .ant-tabs-tab {
        background: #f2f3f5;
        padding: 6px 18px;
        border-radius: 999px;
        color: #4e5969;

        &:hover {
          color: #37aeb9;
        }
      }

      .ant-tabs-tab-active {
        background: #37aeb9;
        color: #fff !important;

        .ant-tabs-tab-btn {
          color: #fff !important;
        }
      }

      .ant-tabs-ink-bar {
        display: none !important;
      }
    }
  }
}

.tree-switcher-noop-small {
  .ant-tree-switcher-noop {
    width: 12px !important;
  }
}

.form-item-error-float {
  .ant-form-item-explain {
    white-space: nowrap;
    position: absolute;
    bottom: -24px;
  }
}

.select-no-x-padding {
  .ant-select-selector {
    padding: 0 !important;
  }
}

.select-color-initial {
  .ant-select-selector {
    color: #1c212a !important;
  }
}

.select-color-primary {
  .ant-select-selection-item,
  .ant-select-arrow {
    color: #00b1bb !important;
  }
}

.date-picker-color-initial {
  .ant-picker-input input {
    color: #1c212a !important;
  }
}

.table-relative {
  .ant-table-content {
    table {
      position: relative;
    }
  }
}

.table-scroll-smoothly {
  .ant-table-content {
    scroll-behavior: smooth;
    //transition: all 0.2s ease-in-out;
  }
}

.rule-process {
  .ant-progress-inner {
    width: 47px !important;
    height: 47px !important;
    padding: 2px;
    border: 1px solid #e8e8e8;
  }
}

.process-large {
  .ant-progress-bg {
    height: 16px !important;
  }
}

.tooltip-bg-primary {
  .ant-tooltip-content {
    --antd-arrow-background-color: #00B1BB;
  }

  .ant-tooltip-inner {
    background: #00B1BB !important;
  }
}
